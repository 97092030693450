.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

nav a {
  display: flex;
  font-size: 32px;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
}

nav a:hover {
  color: #39FF14;
}

.menu {
  align-items: flex-end;
  display: inline-block;
  transform: scale(0.9);
  transform-origin: center right;
  z-index: 100;
}

.hamburger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 64px;
  cursor: pointer;
}

.hamburger:hover {
  opacity: 0.8;
  animation: rubberBand 1s;
}

.hamburger span {
  background: #39FF14;
  border-radius: 0px;
  height: 4px;
  margin: 6px 0;
  transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.hamburger span:nth-of-type(1) {
  width: 50%;
}

.hamburger span:nth-of-type(2) {
  width: 100%;
}

.hamburger span:nth-of-type(3) {
  width: 75%;
}

.close span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(-45deg) translate(-4px, 0px)
}

.close span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(45deg)
}

.close span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(-27px, -5px) rotatez(-45deg);
}

@media (max-width: 768px) {
  .menu {
    transform: scale(0.6);
  }
}
