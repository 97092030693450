.App {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

header {
  display: flex;
  flex-direction: row;
  max-width: 750px;
  margin: 16px auto;
}

.logo {
  align-items: flex-start;
  color: rgb(57, 255, 20);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 36px;
}

.logo:hover {
  opacity: 0.8;
  animation: rubberBand 1s;
}

.spacer {
  flex-grow: 1;
}

.stage {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.stage img {
  max-width: 100%;
}

.stage video {
  max-width: 100%;
}

.stage h2 {
  font-family: 'Anton', sans-serif;
  color: #38FF14;
  font-size: 80px;
  max-width: 520px;
  min-width: 300px;
  position: absolute;
  font-size: 64px;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  transform: translate(-50%, -50%);
}

.stage h2 span {
  display: inline-block;
  text-align-last: justify;
}

.quote {
  color: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

main {
  max-width: 750px;
  margin: 0 auto;
  text-align: left;
}

h2, h3, ul, li, p {
  font-family: 'Courier New';
}

h2 {
  font-family: 'Anton', sans-serif;
  color: rgb(56, 255, 20);
}

p {
  text-align: justify;
}

main a {
  color: white;
}

main a:hover {
  color: red;
}

li {
  margin: 12px;
}

img[alt=portrait] {
  width: 240px;
  float: right;
  margin: 0 12px 12px;
}

img[alt=work] {
  display: inline-block;
  vertical-align: text-top;
  width: calc(50% - 10px);
  max-width: 400px;
  max-height: 90vh;
  margin-bottom: 24px;
}

footer {
  font-family: 'Courier New';
  margin-top: 24px;
  color: #39FF14;
  opacity: 0.8;
  font-size: 14px;
  font-weight: lighter;
}

footer div {
  margin: 24px;
}

.icon {
  width: 48px;
  margin-left: 6px;
  fill: #39FF14;
}

.icon:hover {
  opacity: 0.8;
  animation: rubberBand 1s;
}

@media (max-width: 768px) {
  main {
    padding: 0 24px;
  }

  header {
    margin: 8px 24px;
  }

  .logo {
    font-size: 28px;
  }

  .menu {
    transform: scale(0.6);
  }

  .stage {
    margin: 0 -24px;
  }

  .stage h2 {
    font-size: 52px;
  }

  img[alt=portrait] {
    float: none;
    margin: 0;
  }
}
